:root {
    --golden: #ba906d;
    --golden-100: #EAE3DE;
    --h: 0;
    --s: 0%;
}

$primary: var(--golden);
$primary-light: var(--golden-100);

@mixin text-grey($l: 94%) {
    color: hsl(var(--h), var(--s), $l);
}

@mixin bg-grey($l: 94%) {
    background-color: hsl(var(--h), var(--s), $l);
}

@mixin border-grey($position, $width: 2px, $l: 94%) {

    @if $position ==top {
        border-top: $width solid hsl(var(--h), var(--s), $l);
    }

    @if $position ==left {
        border-left: $width solid hsl(var(--h), var(--s), $l);
    }

    @if $position ==bottom {
        border-bottom: $width solid hsl(var(--h), var(--s), $l);
    }

    @if $position ==right {
        border-right: $width solid hsl(var(--h), var(--s), $l);
    }
}