* {
    box-sizing: border-box;
}

:root {
    --aside-width: 40vw;
    --logo-width: 140px;
    --nav-width: calc(var(--aside-width) - var(--logo-width));
    --p-30: 30px;
    --header_height: 65px;
    --wrapper_body_height: calc(100vh - var(--header_height));
}

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @include bg-grey();
}

a {
    text-decoration: none;
    color: inherit;
}

button {
    cursor: pointer;
    border: 0;
    outline: 0;
    box-shadow: none;
}

.empty {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}