.wrapper-body {
    display: flex;

    @media screen and (max-width:768px) {
        flex-direction: column;
    }

    >* {
        height: var(--wrapper_body_height);
        overflow: auto;
    }

    aside {
        // display: flex;
        // align-items: center;
        // justify-content: center;
        flex: 0 1 var(--aside-width);
        padding: 60px;
        background: #fff;

        .empty {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: calc(100vh - 190px);

            p {
                @include text-grey($l: 65%);
                font-weight: 500;
            }
        }

        .order-detail {
            flex: 1;
            --color: #5D5D5D;
            --font-weight: 600;
            --font-size: 18px;
            padding-bottom: 100px;

            &--head {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                // flex-wrap: wrap;
                @include border-grey(bottom);
                --p: 8px 0;

                .user-detail {
                    h3 {
                        font-size: 24px;
                        font-weight: 500;
                        margin: 0;
                        padding: var(--p);
                    }

                    h4 {
                        font-size: var(--font-size);
                        color: var(--color);
                        margin: 0;
                        padding: var(--p);
                        font-weight: var(--font-weight);

                    }
                }

                .status {
                    text-align: right;

                    p {
                        background-color: var(--golden);
                        margin-left: 0;
                        padding: 9px 46px;
                        background-color: var(--golden-100);
                        color: var(--golden);
                        font-size: 16px;
                        font-weight: 600;
                        text-transform: uppercase;
                        border-radius: 50px;
                        display: inline-block;
                        margin: 0;
                    }


                    h3 {
                        font-size: 14px;
                        color: #000000;
                        font-weight: 500;
                        margin: 0;
                        // padding: 14px 0;

                    }

                    h6 {
                        font-size: 12px;
                        margin: 0;
                        padding: 27px 0 8px 0;
                    }

                    h4 {
                        margin: 0;
                        padding: 8px 0;
                        color: var(--color);
                        font-size: 20px;
                        font-weight: 400;

                    }


                }


            }

            .schedule {
                @include border-grey(bottom);
                display: flex;
                align-items: center;
                justify-content: space-between;

                p {
                    margin: 0;
                    padding: 20px 0;
                    font-size: var(--font-size);
                    color: #5D5D5D;
                    font-weight: 600;

                    span {
                        padding-left: 16px;
                        color: $primary;

                    }

                    .time {
                        padding-left: 40px;
                        color: #5D5D5D;
                        padding-right: 0;
                    }
                }
            }

            .notes {
                @include border-grey(bottom);
                padding: 20px 0;

                h4 {
                    font-size: var(--font-size);
                    color: var(--color);
                    font-weight: var(--font-weight);
                    margin: 0;
                    padding-bottom: 8px;
                }

                p {
                    margin: 0;
                    font-size: var(--font-size);
                    color: #000000;
                    line-height: 24px;
                    text-align: left;
                }
            }

            .actions {
                display: flex;
                margin-top: 20px;
                flex-wrap: wrap;

                button {
                    flex: 1;
                }
            }

            .list-items {
                margin-top: 30px;


                h3 {
                    color: var(--color);
                    font-weight: var(--font-weight);
                    font-size: var(--font-size);
                    margin: 0;
                }

                .category_group {
                    @include border-grey(bottom);

                    .head {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        background-color: #F4F4F4;
                        margin-top: 20px;


                        .title {
                            display: flex;
                            padding: 10px 12px;
                            align-items: center;
                            width: 100%;
                            justify-content: space-between;

                            h4 {
                                margin: 0;
                            }

                            p {
                                margin: 0;
                                font-size: 16px;
                                color: #000000;
                            }
                        }

                        button {
                            background-color: $primary-light;
                            padding: 10px 12px;

                            img {
                                width: 20px;
                            }
                        }
                    }

                    table {
                        width: 100%;
                        padding: 10px 0;

                        tr {
                            td {
                                font-size: var(--font-size);
                                color: #000000;
                                padding: 5px;
                            }
                        }
                    }
                }
            }

            .schedule-button {
                text-align: flex-end;
                margin-top: 40px;
            }
        }
    }


    .outer {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        padding: var(--p-30);
        gap: 10px;
        align-content: flex-start;

        .order_item-card {
            --p: 12px 16px;

            flex: 1 0 190px;
            background-color: #fff;
            border-radius: 3px;
            box-shadow: 0 1px 4px rgba(0 0 0 / 12%);
            max-width: calc(100%/3 - 10px);
            transition: .2s box-shadow ease;
            cursor: pointer;

            &:hover {
                box-shadow: 0 3px 7px rgba(0 0 0 / 20%);
                transition: .2s box-shadow ease;
            }

            &.active {
                outline: 3px solid $primary;
                box-shadow: 0 1px 8px $primary;
            }

            * {
                margin: 0;
                font-weight: 400;
            }

            &--header {
                display: flex;
                padding: var(--p);
                @include border-grey(bottom);

                h3 {
                    flex: auto;
                    color: $primary;
                    font-weight: 700;
                    text-transform: uppercase;
                    font-size: 15px;

                    &:last-child {
                        color: black;
                        text-align: right;
                        font-weight: 400;
                    }
                }
            }

            &--body {
                padding: var(--p);
                padding-bottom: 25px;

                h4 {
                    margin-top: 8px;
                    @include text-grey(57%)
                }
            }

            &.new_order {
                .order_item-card--body {
                    position: relative;
                    display: grid;
                    grid-template-columns: 1fr auto;

                    >*:not(:last-child) {
                        grid-column: 1/-1;
                    }

                    :last-child {
                        grid-column: 1/2;
                    }

                    &::after {
                        --color: #15B45D;
                        content: 'New';
                        background-color: #CBFCE1;
                        border: 1px solid var(--color);
                        color: var(--color);
                        padding: 4px 12px;
                        font-size: 12px;
                        font-weight: 700;
                        text-transform: uppercase;
                        border-radius: 50px;
                        grid-column: 2/-1;
                        display: flex;
                        align-items: center;
                        align-self: flex-end;
                    }
                }
            }
        }
    }
}

// Log-in page css start 
.log-in {
    background-image: linear-gradient(140deg, #BA906D, #E3C3A7);
    flex: 1;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .file-form {
        max-width: 1140px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 9;
        padding-left: 18px;

        @media screen and (max-width:992px) {
            flex-direction: column;
            padding: 0 20px;
        }

        .logo-image-dark {
            max-width: 350px;
            width: 100%;
            text-align: center;

            img {
                width: 250px;
                margin: 0px 0 50px;
            }

        }

        .details-user-login {
            flex: 1;
            padding-left: 10px;
            padding-top: 30px;


            @media screen and (max-width:99px) {
                padding-left: 0;
                padding-top: 0;
            }

            .form-heading {
                max-width: 540px;
                width: 100%;
                margin: 0 auto;
                transform: translateY(-90px);

                @media screen and (max-width :1050px) {
                    padding-left: 50px;
                }

                @media screen and (max-width :992px) {
                    transform: translateY(0px);
                    padding-left: 0;
                }

                h3 {
                    font-size: 30px;
                    font-weight: 500;
                    color: #fff;
                    line-height: 20px;
                    margin: 0;
                    padding-bottom: 12px;
                }

                p {
                    margin: 0;
                    font-size: 18px;
                    color: hsla(0, 0%, 100%, .502);
                    font-weight: 600;
                }
            }
        }

        form {
            flex: 1;
            padding: 0 100px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding-bottom: 10px;
            margin: 0 auto;

            @media screen and (max-width:992px) {
                padding: 0 0;
            }

            .label-name {
                color: #6f4929;
                font-size: 20px;
                font-weight: 500;
                line-height: 25px;
                margin: 10px 0;

            }

            .input-control {
                margin-bottom: 10px;
                width: 100%;
                padding: 16px 10px;
                border: transparent;
                box-shadow: none;
                background-color: #d8bca3 !important;
                border: 1px solid hsla(0, 0%, 100%, .506);
                border-radius: 5px;

                &:focus-visible {
                    box-sizing: none;
                    outline: none;

                }

                &::placeholder {
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }

        .log-in-button {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 30px;

            .theme-button {
                background-color: #000;
                border: 1px solid transparent;
                padding: 16px 60px;
                border-radius: 5px;
            }
        }

        .password-forget {
            color: rgba(0, 0, 0, .361);
            font-size: 1rem;
            margin: auto;
            flex: 1;
            position: relative;
            -webkit-transform: translateY(50px);
            transform: translateY(50px);
            z-index: 3;

            @media screen and (max-width :992px) {
                transform: translateY(0px);
                padding-top: 20px;
                padding-bottom: 30px;
            }
        }

    }

    .images-f {
        bottom: 0;
        left: -70px;
        position: absolute;
        z-index: 0;

        img {
            max-width: 800px;
            width: 100%;
        }
    }
}

// Log-in page css end

// Log-out css start popup
.overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #00000056;
}

.modal-dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .action-buttons {
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, .2);
        border-radius: 0.3rem;
        padding: 2rem;
        border: 1px solid #fff;
        display: flex;
        gap: 20px;

        .open-button {
            padding: 20px 70px;
            background-color: #efefef;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            border: 1px solid #d2d2d2;

            img {
                width: 25px;
                padding-bottom: 15px;
            }

            span {
                font-size: 16px;
                text-transform: uppercase;
                font-weight: 600;
            }
        }


    }
}

// Log-out css end popup