header {
    display: flex;
    border-bottom: 2px solid white;

    >* {
        padding: 0 var(--p-30);
    }

    >*:not(.logo) {
        @include bg-grey($l: 97%);
    }

    >*:not(:last-child) {
        border-right: 2px solid white;
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 var(--logo-width);
        max-width: var(--logo-width);

        img {
            max-width: 100%;
        }
    }

    nav {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 8px;
        flex: 0 1;
        flex-basis: var(--nav-width);

        button {
            @include text-grey($l: 36%);
            padding: 8px 15px;
            background-color: $primary-light;
            border-radius: 4px;
            font-size: 11px;
            font-weight: 700;
            text-transform: uppercase;

            &.active {
                color: $primary-light;
                background-color: $primary;
            }

            &:hover:not(.active) {
                color: $primary;
            }
        }
    }

    .page-title {
        flex: 1;
        display: flex;
        align-items: center;

        h3 {
            margin: 0;
            font-size: 18px;
            font-weight: 500;
        }

        span {
            color: $primary;
        }
    }

    .current-date {
        padding: 0 60px;
        text-transform: uppercase;
        @include text-grey(67%);

        h4 {
            font-weight: 500;
        }
    }
}