.theme-button {
    appearance: none;
    -webkit-appearance: none;
    border: 1px solid white;
    background-color: $primary-light;
    color: $primary;
    outline: 0;
    box-shadow: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
    gap: 8px;
    padding: 15px 10px;
    text-transform: uppercase;

    &.disabled {
        pointer-events: none;
        opacity: 0.4;
    }

    &.gray {
        padding: 18px 30px;
        border-radius: 5px;
        @include bg-grey($l: 85%);
        @include text-grey($l: 45%);
    }

    &.black {
        background-color: #000000;
        padding: 18px 30px;
        border-radius: 5px;

        &:hover {
            color: #fff;
        }
    }

}